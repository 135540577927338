<template>
  <div>
    <custom-snackbar
      ref="snackbar"
    />

    <v-data-table
      dense
      :headers="headers"
      :items="data"
      sort-by="id"
      class="elevation-1 p-5"
      :search="search"
      :items-per-page="$dataTableConfig.getItemsPerPage()"
      :footer-props="$dataTableConfig.getFooterProps()"
      :loading-text="$dataTableConfig.getLoadText()"

      :page="page"
      :page-count="pageCount"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          class="d-flex flex-row-reverse"
        >
          <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                dark
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Adicionar Atividade Agrícola
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="info"
                dark
              >
                <span class="headline">{{ formTitle }}</span>
              </v-toolbar>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-if="showForm"
              >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        class="pb-0"
                      >
                        <v-text-field
                          v-model="editedItem.descricao"
                          label="Descrição"
                          dense
                          outlined
                          :rules="descricaoRule"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        class="pb-0"
                      >
                        <v-select
                          v-model.number="editedItem.tipo_atividade_id"
                          :items="tipo_atividade"
                          label="Tipo de atividade"
                          :rules="tipoRule"
                          item-text="tipo"
                          item-value="id"
                          dense
                          outlined
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        class="pb-0"
                      >
                        <v-autocomplete
                          v-model="produtos"
                          label="Produto(s)"
                          :items="produtosOptions"
                          :rules="produtoRule"
                          item-text="descricao"
                          item-value="id"
                          multiple
                          dense
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model.number="editedItem.status"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            Status:
                            <custom-label
                              class="ml-2"
                              :color="$label.getColor(editedItem.status)"
                              :text="$label.getStatusMessage(editedItem.status)"
                            />
                          </template>
                        </v-switch>
                      </v-col>

                      <v-col>
                        <v-switch
                          v-model.number="editedItem.quantidade"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            Exigir Quantidade:
                            <custom-label
                              class="ml-2"
                              :color="$label.getColor(editedItem.quantidade)"
                              :text="$label.getMessage(editedItem.quantidade)"
                            />
                          </template>
                        </v-switch>
                      </v-col>

                      <v-col>
                        <v-switch
                          v-model.number="editedItem.exige_talhao"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            Exigir Talhão:
                            <custom-label
                              class="ml-2"
                              :color="$label.getColor(editedItem.exige_talhao)"
                              :text="$label.getMessage(editedItem.exige_talhao)"
                            />
                          </template>
                        </v-switch>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model.number="editedItem.horas_trabalhadas"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            Exigir Horas Trabalhadas:
                            <custom-label
                              class="ml-2"
                              :color="
                                $label.getColor(editedItem.horas_trabalhadas)
                              "
                              :text="
                                $label.getMessage(editedItem.horas_trabalhadas)
                              "
                            />
                          </template>
                        </v-switch>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model.number="editedItem.historico"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            Exigir Histórico:
                            <custom-label
                              class="ml-2"
                              :color="$label.getColor(editedItem.historico)"
                              :text="$label.getMessage(editedItem.historico)"
                            />
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="validate"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-form>
              <v-card-text v-else>
                <div class="text-center p-5">
                  <h4 class="mb-4">
                    Aguarde...
                  </h4>
                  <v-progress-circular
                    :size="100"
                    :width="2"
                    color="primary"
                    indeterminate
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
          >
            <v-card class="bg-danger text-white">
              <v-card-title class="headline word-breaker">
                <p>
                  Tem certeza que deseja excluir a atividade
                  {{ editedItem.descricao }}?
                </p>
              </v-card-title>

              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-show="loadingDelete"
                  :size="50"
                  :width="6"
                  color="white"
                  indeterminate
                />
              </v-card-text>

              <v-card-actions v-show="!loadingDelete">
                <v-spacer />
                <v-btn
                  color="white"
                  text
                  outlined
                  large
                  @click="closeDelete"
                >
                  Não
                </v-btn>
                <v-btn
                  color="white"
                  text
                  outlined
                  large
                  @click="deleteItemConfirm"
                >
                  Sim
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row class="mt-3">
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-select
              v-model="qtdeFilterValue"
              :items="boolOptions"
              outlined
              dense
              label="Exige Quantidade"
              item-text="text"
              item-value="value"
              @change="getData"
            />
          </v-col>

          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-select
              v-model="talhaoFilterValue"
              :items="boolOptions"
              outlined
              dense
              label="Exige Talhão"
              item-text="text"
              item-value="value"
              @change="getData"
            />
          </v-col>

          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-select
              v-model="horasFilterValue"
              :items="boolOptions"
              outlined
              dense
              label="Exige Horas Trabalhadas"
              item-text="text"
              item-value="value"
              @change="getData"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-select
              v-model="historicoFilterValue"
              :items="boolOptions"
              outlined
              dense
              label="Exige Histórico"
              item-text="text"
              item-value="value"
              @change="getData"
            />
          </v-col>

          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-select
              v-model="tipoAtividadeFilterValue"
              :items="tipoAtividadeOptions"
              label="Tipo de atividade"
              dense
              outlined
              item-text="tipo"
              item-value="id"
              @change="getData"
            />
          </v-col>

          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-select
              v-model="statusFilterValue"
              :items="statusOptions"
              outlined
              dense
              label="Status"
              item-text="text"
              item-value="value"
              @change="getData"
            />
          </v-col>
        </v-row>

        <v-row class="mb-1">
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-text-field
              v-model="search"
              outlined
              dense
              label="Pesquisar"
              append-icon="mdi-magnify"
              @keyup="getData"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.quantidade`]="{ item }">
        <custom-label
          :color="$label.getColor(item.quantidade)"
          :text="$label.getMessage(item.quantidade)"
        />
      </template>
      <template v-slot:[`item.exige_talhao`]="{ item }">
        <custom-label
          :color="$label.getColor(item.exige_talhao)"
          :text="$label.getMessage(item.exige_talhao)"
        />
      </template>
      <template v-slot:[`item.horas_trabalhadas`]="{ item }">
        <custom-label
          :color="$label.getColor(item.horas_trabalhadas)"
          :text="$label.getMessage(item.horas_trabalhadas)"
        />
      </template>
      <template v-slot:[`item.historico`]="{ item }">
        <custom-label
          :color="$label.getColor(item.historico)"
          :text="$label.getMessage(item.historico)"
        />
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <custom-label
          :color="$label.getColor(item.status)"
          :text="$label.getStatusMessage(item.status)"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-spacer />
        <div v-if="!loading">
          <v-row class="justify-center mt-1">
            <v-col class="col-lg-6">
              <custom-alert
                :color="$label.getColor(0)"
                text="Não foi possível obter essas informações"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style type="text/css">
.word-breaker {
  word-break: break-word;
}
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    valid: true,
    showForm: true,
    descricaoRule: [
      (v) => !!v || 'Necessário informar a descrição da Atividade',
    ],
    tipoRule: [(v) => !!v || 'Necessário informar o tipo de atividade'],
    produtoRule: [(v) => !!v || 'Necessário informar um ou mais Produtos'],
    search: '',
    dialog: false,
    dialogDelete: false,
    loading: false,
    loadingDelete: false,
    dataError: false,
    statusOptions: [
      {
        text: 'Ativo',
        value: '1',
      },
      {
        text: 'Inativo',
        value: '0',
      },
      {
        text: 'Todos',
        value: '',
      },
    ],
    boolOptions: [
      {
        text: 'Sim',
        value: '1',
      },
      {
        text: 'Não',
        value: '0',
      },
      {
        text: 'Todos',
        value: '',
      },
    ],
    tipoAtividadeOptions: [
      {
        id: '',
        tipo: 'Todos',
      },
    ],
    statusFilterValue: '',
    qtdeFilterValue: '',
    talhaoFilterValue: '',
    horasFilterValue: '',
    historicoFilterValue: '',
    tipoAtividadeFilterValue: '',
    data: [],
    tipo_atividade: [],
    produtosOptions: [],
    produtos: [],
    editedIndex: -1,
    editedItem: {
      descricao: '',
      quantidade: 0,
      exige_talhao: 0,
      horas_trabalhadas: 0,
      historico: 0,
      tipo_atividade_id: 0,
      status: 1,
    },
    defaultItem: {
      descricao: '',
      quantidade: 0,
      exige_talhao: 0,
      horas_trabalhadas: 0,
      historico: 0,
      tipo_atividade_id: 0,
      status: 1,
    },

    page: 1,
    pageCount: 0,
    options: {},
    totalItems: 0,
  }),
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'Adicionar Atividade'
        : 'Editar Atividade'
    },
    headers () {
      return [
        {
          text: '#',
          align: 'start',
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        {
          text: 'Tipo de Atividade',
          value: 'tipos_atividades.tipo',
        },
        {
          text: 'Qtde.',
          value: 'quantidade',
        },
        {
          text: 'Exige Talhão',
          value: 'exige_talhao',
        },
        {
          text: 'Horas Trabalhadas',
          value: 'horas_trabalhadas',
        },
        {
          text: 'Histórico',
          value: 'historico',
        },
        {
          text: 'Status',
          value: 'status',
        },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    options: {
      handler () {
        this.getData()
      },
    },
  },
  created () {},
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações', route: 'configuracoes' },
      { title: 'Agrícola', route: 'configuracoes/agricola' },
      { title: 'Atividades Agricolas' },
    ])

    this.initialize()
  },
  methods: {
    async initialize () {
      await this.getTipoAtividade()
      await this.getProduto()
    },
    getData () {
      this.loading = true
      this.data = []
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let params = `?page=${page}&qtde=${itemsPerPage}&sortBy=${sortBy}&sortDesc=${sortDesc}`

      if (this.search !== '') {
        params += `&search=${this.search}`
      }

      if (this.qtdeFilterValue !== '') {
        params += `&quantidade=${this.qtdeFilterValue}`
      }

      if (this.talhaoFilterValue !== '') {
        params += `&exige_talhao=${this.talhaoFilterValue}`
      }

      if (this.horasFilterValue !== '') {
        params += `&horas_trabalhadas=${this.horasFilterValue}`
      }

      if (this.historicoFilterValue !== '') {
        params += `&historico=${this.historicoFilterValue}`
      }

      if (this.statusFilterValue !== '') {
        params += `&status=${this.statusFilterValue}`
      }

      if (this.tipoAtividadeFilterValue !== '') {
        params += `&tipo_atividade_id=${this.tipoAtividadeFilterValue}`
      }

      ApiService.get('/atividade-agricola', params).then((res) => {
        const dados = res.data
        this.loading = false
        this.totalItems = dados.total
        this.pageCount = dados.pageCount
        this.data = dados.data
      }).catch((error) => {
        this.loading = false
        console.error('Erro: ', error)
      })
    },

    async getTipoAtividade () {
      try {
        const response = await ApiService.get('/tipo-atividade')
        this.tipo_atividade = response.data.data

        for (const tipo of this.tipo_atividade) {
          this.tipoAtividadeOptions.push(tipo)
        }
      } catch (error) {}
    },
    async getProduto () {
      try {
        const response = await ApiService.get('/sapiens/produto')
        this.produtosOptions = response.data.data
      } catch (error) {}
    },
    insertAtividadeProduto (res) {
      for (let i = 0; i < this.produtos.length; i++) {
        const atividadeAgricolaProduto = {}
        atividadeAgricolaProduto.atividade_id = res.data.data.id
        atividadeAgricolaProduto.produto_id = this.produtos[i]

        ApiService.post(
          '/atividade-agricola-produto',
          atividadeAgricolaProduto,
        )
      }
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.showForm = false
        const index = this.editedIndex

        if (this.editedIndex > -1) {
          ApiService.get(
            `/atividade-agricola-produto/${this.editedItem.id}`,
          ).then((res) => {
            const produtos = res.data.data

            for (const produto of produtos) {
              ApiService.delete(`/atividade-agricola-produto/${produto.id}`)
            }
          })

          ApiService.put(
            `/atividade-agricola/${this.editedItem.id}`, this.editedItem).then((res) => {
            this.insertAtividadeProduto(res)
            Object.assign(this.data[index], res.data.data)
            this.showForm = true
            this.$refs.snackbar.show('Sucesso!', `Atividade ${this.editedItem.descricao} atualizada com sucesso`)
            this.getData()
            this.close()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível atualizar a Atividade', 'danger')
            console.error('Erro: ', error)
          })
        } else {
          ApiService.post('/atividade-agricola', this.editedItem).then((res) => {
            this.insertAtividadeProduto(res)
            this.data.push(res.data.data)
            this.showForm = true
            this.$refs.snackbar.show('Sucesso!', `Atividade ${this.editedItem.descricao} adicionada com sucesso`)
            this.getData()
            this.close()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível adicionar a Atividade', 'danger')
            console.error('Erro: ', error)
          })
        }
      }
    },
    editItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      ApiService.get(`/atividade-agricola-produto/${this.editedItem.id}`).then(
        (res) => {
          const produtos = res.data.data

          const produtosUnicos = produtos.reduce((unique, o) => {
            if (!unique.some((obj) => obj.produto_id === o.produto_id)) {
              unique.push(o)
            }

            return unique
          }, [])

          for (const produto of produtosUnicos) {
            this.produtos.push({
              id: produto.produto_id,
            })
          }

          this.getData()
        },
      )

      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.loadingDelete = true

      ApiService.delete(`/atividade-agricola/${this.editedItem.id}`).then(() => {
        this.data.splice(this.editedIndex, 1)
        this.$refs.snackbar.show('Sucesso!', `Atividade ${this.editedItem.descricao} removida com sucesso`)
        this.loadingDelete = false
        this.closeDelete()
        this.getData()
      }).catch((error) => {
        this.loadingDelete = false
        this.$refs.snackbar.show('Erro', 'Não foi possível remover a Atividade', 'danger')
        console.error('Erro: ', error)
        this.closeDelete()
      })
    },
    close () {
      this.produtos = []
      this.dialog = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    closeDelete () {
      this.dialogDelete = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem)
      } else {
        this.data.push(this.editedItem)
      }

      this.close()
    },
  },
}
</script>
